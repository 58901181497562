import React from 'react';

import { Flex, Grid } from '@chakra-ui/react';

import { ClearButton } from '@/components/DiagramInteractionButtons/ClearButton';

import { PrizeIcon } from '@/icons/Prize.icon';

import { useMatchesStore } from '@/store/hooks/useMatchesStore.hook';

import { RandomButton } from './RandomButton';

interface DiagramInteractionButtonsProps {
  variant: 'desktop' | 'mobile';
}

const DiagramInteractionButtons: React.FC<DiagramInteractionButtonsProps> = ({
  variant,
}) => {
  const { isBracketEnabled } = useMatchesStore();

  // Hide buttons if Bracket disabled
  if (!isBracketEnabled) return null;

  return (
    <>
      {variant === 'desktop' ? (
        <Flex
          alignItems='center'
          gap='8px'
          justifyContent='flex-end'
          p='16px'
          w='100%'
        >
          <RandomButton />
          {/*<Button*/}
          {/*  rightIcon={<PrizeIcon fill='currentColor' fontSize='15' />}*/}
          {/*  size='sm'*/}
          {/*  variant='ghost'*/}
          {/*>*/}
          {/*  Favs*/}
          {/*</Button>*/}
          <ClearButton />
        </Flex>
      ) : (
        <Grid
          alignItems='center'
          gap='8px'
          py='8px'
          templateColumns='repeat(2, 1fr)'
        >
          <RandomButton />

          {/*<Button*/}
          {/*  rightIcon={<PrizeIcon fill='currentColor' fontSize='15' />}*/}
          {/*  size='md'*/}
          {/*  variant='outlined'*/}
          {/*>*/}
          {/*  Favs*/}
          {/*</Button>*/}
          <ClearButton />
        </Grid>
      )}
    </>
  );
};

export default DiagramInteractionButtons;
