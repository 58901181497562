export const ENABLED_LANGUAGES =
  (process.env.REACT_APP_ENABLED_LANGUAGES as string) || 'en';
export const SHARE_URL = process.env.REACT_APP_SHARE_URL as string;

const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_GAMEZONE_URL: process.env.REACT_APP_GAMEZONE_URL,
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    OVERALL_LEADERBOARD_ID: Number(
      process.env.REACT_APP_OVERALL_LEADERBOARD_ID,
    ),
    ALL_SPORTS_OVERALL_LEAGUE_ID: Number(
      process.env.REACT_APP_ALL_SPORTS_OVERALL_LEAGUE_ID,
    ),
    BASKETBALL_OVERALL_LEAGUE_ID: Number(
      process.env.REACT_APP_BASKETBALL_OVERALL_LEAGUE_ID,
    ),
    BASKETBALL_NBA_LEAGUE_ID: Number(
      process.env.REACT_APP_BASKETBALL_NBA_LEAGUE_ID,
    ),
    BASKETBALL_NCAA_LEAGUE_ID: Number(
      process.env.REACT_APP_BASKETBALL_NCAA_LEAGUE_ID,
    ),
    HOCKEY_OVERALL_LEAGUE_ID: Number(
      process.env.REACT_APP_HOCKEY_OVERALL_LEAGUE_ID,
    ),
    SOCIAL_TITLE: process.env.REACT_APP_SOCIAL_TITLE as string,
    SOCIAL_URL: process.env.REACT_APP_SOCIAL_URL as string,
    SOCIAL_DESCRIPTION: process.env.REACT_APP_SOCIAL_DESCRIPTION as string,
    PREDICTOR_LOCATION_ORIGIN: process.env
      .REACT_APP_PREDICTOR_LOCATION_ORIGIN as string,
  };
}

export const {
  ALL_SPORTS_OVERALL_LEAGUE_ID,
  BASKETBALL_OVERALL_LEAGUE_ID,
  OVERALL_LEADERBOARD_ID,
  BASKETBALL_NBA_LEAGUE_ID,
  BASKETBALL_NCAA_LEAGUE_ID,
  HOCKEY_OVERALL_LEAGUE_ID,
  SOCIAL_TITLE,
  SOCIAL_URL,
  SOCIAL_DESCRIPTION,
  PREDICTOR_LOCATION_ORIGIN,
} = window.config;

export const GAMEZONE_URL = window.config.REACT_APP_GAMEZONE_URL as string;

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;
