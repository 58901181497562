import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  CreateSelectionsReqBody,
  GetBracketResponse,
  GetMatchesRes,
  GetSelectionsRes,
  GetTeamsResponse,
  LoginResponse,
  UpdateSelectionsReqBody,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api.util';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    generateJWTToken: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: '/jwt-redirect',
        method: 'POST',
      }),
    }),

    getBracketSettings: builder.query<GetBracketResponse, void>({
      query: () => '/bracket-setting?type=brackets',
    }),

    getMatches: builder.query<GetMatchesRes, void>({
      query: () => '/matches',
      providesTags: [ApiTagsEnum.GET_MATCHES],
    }),
    getSelections: builder.query<GetSelectionsRes, void>({
      query: () => '/user-selections',
      providesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),
    // TODO: add type
    createSelections: builder.mutation<any, CreateSelectionsReqBody>({
      query: (body) => ({
        url: '/user-selections',
        method: 'POST',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),
    // TODO: add type
    updateSelections: builder.mutation<any, UpdateSelectionsReqBody>({
      query: ({ id, ...body }) => ({
        url: `/user-selections/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),

    getTeams: builder.query<GetTeamsResponse, void>({
      query: () => ({
        url: '/teams?type=bracket',
      }),
    }),
  }),
});

export const {
  useGenerateJWTTokenMutation,
  useGetBracketSettingsQuery,
  useGetMatchesQuery,

  useGetSelectionsQuery,
  useUpdateSelectionsMutation,
  useCreateSelectionsMutation,
  useGetTeamsQuery,
} = api;
