import React, { ReactNode } from 'react';

import { Flex, WithCSSVar } from '@chakra-ui/react';

type Props = {
  theme: WithCSSVar<any>;
  isMobile: boolean;
  children: ReactNode;
};

export const MobileNavigationLayout: React.FC<Props> = (props) => {
  const { theme, isMobile, children } = props;

  return (
    <Flex
      alignItems='center'
      background={isMobile ? theme.colors.navigationGradient : 'transparent'}
      height={isMobile ? '60px' : '90px'}
      justifyContent='space-between'
      padding={isMobile ? '0 24px' : '0 48px'}
    >
      {children}
    </Flex>
  );
};
