import { Button, useDisclosure } from '@chakra-ui/react';

import { ClearBracketModal } from '@/components/Modals/ClearBracketModal/index';

import { useAuthContext } from '@/hooks/useAuthContext.hook';
import { useJWT } from '@/hooks/useJWT.hook';

import { MinusRoundIcon } from '@/icons/MinusRound.icon';

export const ClearButton = () => {
  const {
    isOpen: isClearOpen,
    onOpen: onClearOpen,
    onClose: onClearClose,
  } = useDisclosure({ id: 'clear-modal' });

  const { jwt } = useJWT();
  const { onSignInOpen } = useAuthContext();

  return (
    <>
      <Button
        onClick={jwt ? onClearOpen : onSignInOpen}
        rightIcon={<MinusRoundIcon fill='currentColor' />}
        size={{ base: 'md', md: 'sm' }}
        variant={{ base: 'outlined', md: 'ghost' }}
      >
        Clear
      </Button>

      <ClearBracketModal isOpen={isClearOpen} onClose={onClearClose} />
    </>
  );
};
