import { useState } from 'react';

import { Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Avatar from '@/modules/Navigation/assets/user-profile.svg';
import { MobileNavigation as MobileNavigationComponent } from '@/modules/Navigation/components/MobileNavigation';
import { MobileNavigationRenderer } from '@/modules/Navigation/components/MobileNavigationRenderer';
import { navigation } from '@/modules/Navigation/helpers/navigation.mock';

import { useSidebarContext } from '@/contexts/sidebar.context';

export const MobileNavigation = () => {
  const { pathname } = useLocation();
  const { isSidebarOpen, onSidebarClose, onSidebarToggle } =
    useSidebarContext();
  const [isSideLeaguesOpen, setIsSideLeaguesOpen] = useState(false);

  const toggleSidebarMenu = () => {
    onSidebarToggle();
    if (isSideLeaguesOpen) {
      setIsSideLeaguesOpen(false);
    }
  };

  const handleGoToProfile = () => {
    window.location.assign(navigation.myOpta); // Navigate from Predictor to GH /profile
  };

  return (
    <>
      <MobileNavigationComponent
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
      />

      <div className={` ${isSidebarOpen ? 'blur-sm' : ''}`}>
        <MobileNavigationRenderer
          onToggleSidebarMenu={toggleSidebarMenu}
          pathname={pathname}
          profile={
            <Image
              alt='profile-icon'
              borderRadius='50%'
              cursor='pointer'
              height='32px'
              onClick={handleGoToProfile}
              src={Avatar}
              width='32px'
            />
          }
        />

        <div
          className={`relative transition-transform transform ${
            isSideLeaguesOpen
              ? 'translate-x-[-60vw]'
              : isSidebarOpen
                ? 'blur-sm'
                : '-translate-x-0'
          }`}
        ></div>
      </div>
    </>
  );
};
