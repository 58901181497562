import {
  ALL_SPORTS_OVERALL_LEAGUE_ID,
  BASKETBALL_NBA_LEAGUE_ID,
  BASKETBALL_NCAA_LEAGUE_ID,
  BASKETBALL_OVERALL_LEAGUE_ID,
  HOCKEY_OVERALL_LEAGUE_ID,
} from '@/core/env';

import allSports from './assets/sportsIcons/all-sports.png';
import football from './assets/sportsIcons/american-football.svg';
import baseball from './assets/sportsIcons/baseball.svg';
import basketball from './assets/sportsIcons/basketball.svg';
import hockey from './assets/sportsIcons/ice-hockey.svg';
import soccer from './assets/sportsIcons/soccer.svg';
import { TenantEnum } from './enums/tenant.enum';

export const LEADERBOARD_LIMIT = 10;

/**
 * TENANTS DROPDOWN SETTINGS
 */
const GAMES = {
  OVERALL: { label: 'OVERALL', tenant: TenantEnum.null },
  PICK6: { label: 'TSN Leafs', tenant: TenantEnum.tsnLeafsPick6 },
  SUPER6: { label: 'TSN Raptors', tenant: TenantEnum.tsnRaptorsPick6 },
};
export const GAME_OPTIONS = Object.values(GAMES).map(({ label, tenant }) => ({
  label,
  value: tenant,
}));

/**
 * LEAGUES DROPDOWN SETTINGS
 */
export type LeagueOption = {
  value: number;
  label: string;
  league_id: number;
  image?: string;
};

const BASKETBALL_LEAGUE_OPTIONS: LeagueOption[] = [
  { label: 'OVERALL', league_id: BASKETBALL_OVERALL_LEAGUE_ID || 1 },
  { label: 'NBA', league_id: BASKETBALL_NBA_LEAGUE_ID || 2 },
  { label: 'NCAA', league_id: BASKETBALL_NCAA_LEAGUE_ID || 3 },
].map((item) => ({ ...item, value: item.league_id }));
const ALL_SPORTS_LEAGUE_OPTIONS: LeagueOption[] = [
  {
    label: 'OVERALL',
    league_id: ALL_SPORTS_OVERALL_LEAGUE_ID || 1,
    value: ALL_SPORTS_OVERALL_LEAGUE_ID || 1,
  },
];
const HOCKEY_LEAGUE_OPTIONS: LeagueOption[] = [
  {
    label: 'OVERALL',
    league_id: HOCKEY_OVERALL_LEAGUE_ID || 1,
    value: HOCKEY_OVERALL_LEAGUE_ID || 1,
  },
];

export const getLeaguesBySport = (sport: SportEnums): LeagueOption[] => {
  switch (sport) {
    case SportEnums.ALL_SPORTS:
      return ALL_SPORTS_LEAGUE_OPTIONS;
    case SportEnums.BASKETBALL:
      return BASKETBALL_LEAGUE_OPTIONS;
    case SportEnums.HOCKEY:
      return HOCKEY_LEAGUE_OPTIONS;
    default:
      return [];
  }
};

/**
 * SPORTS DROPDOWN SETTINGS
 */
export enum SportEnums {
  FOOTBALL = 'FOOTBALL',
  BASKETBALL = 'BASKETBALL',
  HOCKEY = 'HOCKEY',
  ALL_SPORTS = 'ALL SPORTS',
  BASEBALL = 'BASEBALL',
}

export type SportOption = {
  value: SportEnums;
  label: SportEnums;
  image: string;
};

export const SPORT_OPTIONS: SportOption[] = [
  { label: SportEnums.ALL_SPORTS, image: allSports },
  // { label: SportEnums.FOOTBALL, image: football },
  { label: SportEnums.BASKETBALL, image: basketball },
  { label: SportEnums.HOCKEY, image: hockey },
  // { label: SportEnums.BASEBALL, image: baseball },
].map((item) => ({ ...item, value: item.label }));
