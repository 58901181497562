import { ComponentStyleConfig } from '@chakra-ui/react';

const defaultButtonStyles = {
  borderRadius: { base: '40px', lg: '12px' },
  color: '#FFFFFF',
  fontSize: '14px',
  h: '44px',
  lineHeight: '1.4',
  maxW: '350px',
  p: '12px 24px',
  textTransform: 'uppercase',
  w: '100%',
};

export const Button: ComponentStyleConfig = {
  variants: {
    primary: {
      ...defaultButtonStyles,
      bg: 'primaryAccentGradient',

      _disabled: {
        bg: 'primaryAccentGradient',
        opacity: '20%',
      },

      _hover: {
        opacity: '90%',

        _disabled: {
          bg: 'primaryAccentGradient',
          opacity: '10%',
        },
      },
    },

    secondary: {
      ...defaultButtonStyles,
      bg: 'white',
      color: 'black',
    },

    ghost: {
      bg: '#C5D0D4',
      color: 'black',
    },

    outline: {
      ...defaultButtonStyles,
      borderColor: '#FFFFFF',
      border: '2px solid',

      _disabled: {
        bg: 'transparent',
        opacity: '40%',
      },

      _hover: {
        bg: '#FFFFFF30',

        _disabled: {
          bg: 'transparent',
          opacity: '30%',
        },
      },
    },

    link: {
      color: '#FFFFFF',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
