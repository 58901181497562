import { Button, useDisclosure } from '@chakra-ui/react';

import { RandomPicksModal } from '@/components/Modals/RandomPicksModal/index';

import { useAuthContext } from '@/hooks/useAuthContext.hook';
import { useJWT } from '@/hooks/useJWT.hook';

import { DominosIcon } from '@/icons/Dominos.icon';

export const RandomButton = () => {
  const { jwt } = useJWT();
  const {
    isOpen: isRandomOpen,
    onOpen: onRandomOpen,
    onClose: onRandomClose,
  } = useDisclosure({ id: 'random-modal' });
  const { onSignInOpen } = useAuthContext();

  return (
    <>
      <Button
        onClick={jwt ? onRandomOpen : onSignInOpen}
        rightIcon={<DominosIcon fill='currentColor' />}
        size={{ base: 'md', md: 'sm' }}
        variant={{ base: 'outlined', md: 'ghost' }}
      >
        Random
      </Button>

      <RandomPicksModal isOpen={isRandomOpen} onClose={onRandomClose} />
    </>
  );
};
