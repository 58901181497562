import React from 'react';

import { Button, Flex, Image, Stack, Text, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ProfileIcon from '@/modules/Navigation/assets/user-profile.svg';
import { MainBanner } from '@/modules/Navigation/components/MainBanner/index';
import { NavigationItem } from '@/modules/Navigation/components/NavigationItem';
import {
  desktopNavigationData,
  navigation,
} from '@/modules/Navigation/helpers/navigation.mock';
import { useNavigation } from '@/modules/Navigation/useNavigation';
import { selectUser } from '@/store';

import { useAuth } from '@/hooks/useAuth';
import { useJWT } from '@/hooks/useJWT.hook';

export const DesktopNavigation: React.FC = () => {
  const theme = useTheme();
  const { jwt } = useJWT();
  const { logout } = useAuth();
  const { handleNavigate } = useNavigation();

  const isLogin: boolean = useSelector(selectUser)?.isLogin || !!jwt;

  return (
    <>
      <MainBanner />
      <Stack
        background={theme.colors.navigationBg}
        direction='row'
        justifyContent='center'
        padding='12px 0'
        position='relative'
      >
        {desktopNavigationData.map((navigation, index) => (
          <NavigationItem
            icon={navigation.icon}
            key={index}
            menuItems={navigation.items}
            onNavigate={handleNavigate}
            path={navigation.path}
            text={navigation.text}
            theme={theme}
          />
        ))}
        <Flex
          alignItems='center'
          gap='16px'
          justifyContent='flex-end'
          position='absolute'
          right='12px'
          top='50%'
          transform='translateY(-50%)'
          zIndex='1'
        >
          <Flex
            alignItems='center'
            cursor='pointer'
            gap='8px'
            onClick={() => window.location.assign(navigation.myOpta)}
          >
            <Image alt='profile' h='33px' src={ProfileIcon} w='33px' />
            <Text fontSize='14px' fontWeight='700'>
              My Opta
            </Text>
          </Flex>
          {isLogin && (
            <Button
              _hover={{ bgColor: '#FFFFFF', color: '#000000' }}
              border='1px solid'
              borderColor='white'
              borderRadius='40px'
              color='white'
              fontSize='14px'
              fontWeight='700'
              onClick={logout}
              px='24px'
              py='10px'
              textTransform='uppercase'
              transition='all 0.2s linear'
              variant='unstyled'
            >
              LOGOUT
            </Button>
          )}
        </Flex>
      </Stack>
    </>
  );
};
