import { lazy } from 'react';

import { FRIENDS_ROUTES } from '@/modules/FriendsLeagues/routes';

import { RoutesEnum } from '@/enums/routes.enum';

import { IRoutes } from '@/routes/types';

const AuthPage = lazy(() => import('@/pages/Auth'));
const HomePage = lazy(() => import('@/pages/Home'));
const LeaderboardPage = lazy(() => import('@/pages/Leaderboard'));
const PrizesPage = lazy(() => import('@/pages/Prizes'));
const HelpPage = lazy(() => import('@/pages/Help'));

export const AppRoutes: IRoutes[] = [
  { path: RoutesEnum.root, element: <HomePage /> },
  { path: RoutesEnum.home, element: <HomePage /> },
  { path: RoutesEnum.leaderboard, element: <LeaderboardPage /> },
  { path: RoutesEnum.prizes, element: <PrizesPage /> },
  { path: RoutesEnum.help, element: <HelpPage /> },
  { path: RoutesEnum.auth, element: <AuthPage /> },
  ...FRIENDS_ROUTES,
];
