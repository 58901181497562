import { Location } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { navigation } from './helpers/navigation.mock';
import { NavigationData } from './types';

export const getIsCurrentPath = (
  navigationData: NavigationData,
  pathname: string,
  location: Location,
): boolean => {
  const { optaStreak, leaderboards } = navigation;

  return navigationData.path !== optaStreak &&
    navigationData.path !== leaderboards
    ? navigationData.path === pathname
    : navigationData.path === location.pathname.concat(location.search);
};

export const getNavigationDropdownItemBorderRadius = (
  navigationData: NavigationData[],
  index: number,
): string => {
  if (index === 0) {
    return '4px 4px 0 0';
  } else if (index === navigationData.length - 1) {
    return '0 0 4px 4px';
  } else {
    return '0';
  }
};

export const getIsNavigationItemActive = (
  pathname: string,
  path: string,
): boolean => {
  return pathname.includes(path);
};

export const getNavigationConfig = (pathname: string) => {
  const {
    root,
    player_rating,
    rules,
    registration,
    login,
    fixture,
    leagues,
    profile,
    activate,
    preview,
    leaderboards,
  } = RoutesEnum;

  return {
    isOptaMax: pathname.includes(root),
    isPlayerRating: pathname.includes(player_rating),
    isGameRules: pathname.includes(rules),
    isAuth:
      pathname.includes(registration) ||
      pathname.includes(login) ||
      pathname.includes(activate),
    isFixture: pathname.includes(fixture),
    isLeaderboard: pathname.includes(leaderboards),
    isLeagues: pathname.includes(leagues),
    isProfile: pathname.includes(profile),
    isPreview: pathname.includes(preview),
  };
};
