import React from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useTheme,
} from '@chakra-ui/react';

import { NavigationData } from '@/modules/Navigation/types';
import {
  getIsNavigationItemActive,
  getNavigationDropdownItemBorderRadius,
} from '@/modules/Navigation/utils';

type Props = {
  text: string;
  pathname: string;
  navigationItems: NavigationData[];
  onNavigate: (path: string) => void;
  isActive: boolean;
};

export const NavigationDropdown: React.FC<Props> = (props) => {
  const { text, pathname, navigationItems, onNavigate, isActive } = props;

  const theme = useTheme();

  return (
    <Menu>
      <MenuButton
        _hover={{ background: theme.colors.mainGradient }}
        as={Button}
        background={
          isActive ? theme.colors.mainGradient : theme.colors.navigationBg
        }
        borderRadius='4px'
        className='navigation-menu-button'
        fontSize='14px'
        height='fit-content'
        leftIcon={
          <Flex
            alignItems='center'
            borderRadius='50%'
            height='28px'
            justifyContent='center'
            sx={{
              background: isActive
                ? theme.colors.navigationBg
                : theme.colors.mainGradient,
              '.navigation-menu-button:hover &': {
                background: theme.colors.navigationBg,
              },
            }}
            width='28px'
          >
            <ChevronDownIcon />
          </Flex>
        }
        padding='4px 8px'
        textTransform='none'
        width='auto'
      >
        {text}
      </MenuButton>
      <MenuList
        background={theme.colors.navigationBg}
        borderColor='transparent'
        borderRadius='4px'
        padding='0'
        zIndex='999'
      >
        {navigationItems.map((item, index, array) => {
          const isItemActive: boolean = getIsNavigationItemActive(
            pathname,
            item.path,
          );

          const itemBorderRadius: string =
            getNavigationDropdownItemBorderRadius(array, index);

          return (
            <MenuItem
              _hover={{ background: theme.colors.mainGradient }}
              background={
                isItemActive
                  ? theme.colors.mainGradient
                  : theme.colors.navigationBg
              }
              borderRadius={itemBorderRadius}
              className='navigation-menu-item'
              fontSize='14px'
              fontWeight='bold'
              key={index}
              onClick={() => onNavigate(item.path)}
              padding='6px 8px'
            >
              <Flex
                alignItems='center'
                borderRadius='50%'
                height='28px'
                justifyContent='center'
                sx={{
                  background: isItemActive
                    ? theme.colors.navigationBg
                    : theme.colors.mainGradient,
                  '.navigation-menu-item:hover &': {
                    background: theme.colors.navigationBg,
                  },
                }}
                width='28px'
              >
                <Image height='14px' src={item.icon} width='14px' />
              </Flex>
              <span style={{ marginLeft: '8px' }}>{item.text}</span>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
