import { FC, useMemo } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useMatchesStore } from '@/store/hooks/useMatchesStore.hook';

import { ModalProps } from '@/types/domain';

import { isAllConferencesExceptFinalCompleted } from '@/utils/bracket.util';

export const RandomPicksModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const {
    isOneConference,
    randomizeBracket,
    randomizeCurrentConference,
    selectedConferenceId,
    conferences,
    matches,
    selections,
  } = useMatchesStore();

  const isFinalConfSelected = useMemo(
    () =>
      conferences.find((x) => x.rounds['A'].length)?.conference_id ===
      selectedConferenceId,
    [conferences, selectedConferenceId],
  );
  const isFinalAndNoOtherConfSelections = useMemo(
    () =>
      isFinalConfSelected &&
      !isAllConferencesExceptFinalCompleted({
        conferences,
        matches,
        selections,
      }),
    [conferences, isFinalConfSelected, matches, selections],
  );

  const handleRandomFull = () => {
    randomizeBracket();
    onClose();
  };
  const handleRandomCurrent = () => {
    randomizeCurrentConference();
    onClose();
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        {!isOneConference && (
          <ModalBody>
            Select to Randomise current screen or full Bracket
          </ModalBody>
        )}

        <ModalFooter>
          {!isOneConference && (
            <Button
              isDisabled={isFinalAndNoOtherConfSelections}
              onClick={handleRandomCurrent}
              size='lg'
              variant='primary'
              w='100%'
            >
              Randomise Current Screen
            </Button>
          )}
          <Button
            onClick={handleRandomFull}
            size='lg'
            variant='secondary'
            w='100%'
          >
            {isOneConference ? 'Randomise Bracket' : 'Randomise Full Bracket'}
          </Button>
          <Button
            mt='20px'
            onClick={onClose}
            size='lg'
            variant='outline'
            w='100%'
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
