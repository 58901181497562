import React, { createContext, useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

type SidebarContextType = {
  onSidebarOpen(): void;
  onSidebarClose(): void;
  onSidebarToggle(): void;
  isSidebarOpen: boolean;
};

const SidebarContext = createContext<SidebarContextType>({
  isSidebarOpen: false,
  onSidebarOpen: () => ({}),
  onSidebarClose: () => ({}),
  onSidebarToggle: () => ({}),
});

const SidebarContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
    onToggle: onSidebarToggle,
  } = useDisclosure();

  useEffect(() => {
    onSidebarClose();
  }, [onSidebarClose, pathname]);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        onSidebarClose,
        onSidebarOpen,
        onSidebarToggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebarContext(): SidebarContextType {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error(
      'useSidebarContext must be used within a SidebarContextProvider',
    );
  }
  return context;
}

export { SidebarContextProvider, useSidebarContext };
