import { Helmet, HelmetProvider } from 'react-helmet-async';

export const MetaProvider = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link href='/favicon.ico' rel='icon' sizes='any' />
          <link href='/icon.svg' rel='icon' type='image/svg+xml' />
          <link href='/apple-touch-icon.png' rel='apple-touch-icon' />
          <link href='/manifest.webmanifest' rel='manifest' />
        </Helmet>
      </HelmetProvider>
    </>
  );
};
