import { GAMEZONE_URL, PREDICTOR_LOCATION_ORIGIN } from '@/core/env';
import bracket from '@/modules/Navigation/assets/bracket.svg';
import fix from '@/modules/Navigation/assets/fixtures.svg';
import rules from '@/modules/Navigation/assets/game-rules.svg';
import how from '@/modules/Navigation/assets/how-to-play.svg';
import leaderboard from '@/modules/Navigation/assets/leaderboard.svg';
import prof from '@/modules/Navigation/assets/my-opta.svg';
import optaMaxIcon from '@/modules/Navigation/assets/opta-max.svg';
import streak from '@/modules/Navigation/assets/streak.svg';
import {
  NavigationData,
  NavigationDataWithoutItems,
} from '@/modules/Navigation/types';

import { RoutesEnum } from '@/enums/routes.enum';

export const generatePathToGamesHub = (route: string) =>
  `${GAMEZONE_URL}${route}`;

export const navigation = {
  points: generatePathToGamesHub(RoutesEnum.player_rating),
  optaMax: generatePathToGamesHub(RoutesEnum.home),
  optaFour: 'https://staging-optafive.statsplatform.com/epl',
  optaStreak: 'https://optastreak.theanalyst.com/tabs/lobby',
  leaderboards: generatePathToGamesHub('/leaderboards?gameId=overall'),
  miniLeagues: generatePathToGamesHub(RoutesEnum.leagues),
  fixtures: generatePathToGamesHub(RoutesEnum.fixture),
  myOpta: generatePathToGamesHub(RoutesEnum.profile),
  howToPlay: generatePathToGamesHub(RoutesEnum.preview),
  gameRules: generatePathToGamesHub(RoutesEnum.rules),
  registration: generatePathToGamesHub(RoutesEnum.registration),
  login: generatePathToGamesHub(RoutesEnum.login),
  activate: generatePathToGamesHub(RoutesEnum.activate),
  preview: generatePathToGamesHub(RoutesEnum.preview),
  superBracket: PREDICTOR_LOCATION_ORIGIN,
  superBracketPrizes: 'open_prize_modal',
};

const OptaSuperBracketLink: NavigationDataWithoutItems = {
  text: 'Opta Super Bracket',
  path: navigation.superBracket,
  icon: bracket,
};

const OptaMaxLink: NavigationDataWithoutItems = {
  text: 'Opta Max',
  path: navigation.optaMax,
  icon: optaMaxIcon,
};

// const OptaPointsLink: NavigationDataWithoutItems = {
//   text: 'Opta Points',
//   path: navigation.points,
//   icon: optaPlayerRatingsIcon,
// };

// const OptaFourLink: NavigationDataWithoutItems = {
//   text: 'Opta Four',
//   path: navigation.optaFour,
//   icon: icon4,
// };

const LeaderBoardLink: NavigationDataWithoutItems = {
  text: 'Leaderboard',
  path: navigation.leaderboards,
  icon: leaderboard,
};

export const mobileNavigationData: NavigationData[] = [
  // OptaPointsLink,
  OptaMaxLink,
  // OptaFourLink,
  {
    text: 'Opta Streak',
    path: navigation.optaStreak,
    icon: streak,
  },
  {
    ...OptaSuperBracketLink,
    items: [
      {
        text: 'Play',
        path: navigation.superBracket,
        icon: streak,
      },
      LeaderBoardLink,
      {
        text: 'Prizes',
        path: navigation.superBracketPrizes,
        icon: streak,
      },
    ],
  },
  LeaderBoardLink,
  // {
  //   text: 'Mini-Leagues',
  //   path: navigation.miniLeagues,
  //   icon: leader,
  // },
  {
    text: 'Fixtures',
    path: navigation.fixtures,
    icon: fix,
  },
  {
    text: 'My Opta',
    path: navigation.myOpta,
    icon: prof,
  },
  {
    text: 'How to Play',
    path: navigation.howToPlay,
    icon: how,
  },
  {
    text: 'Game Rules',
    path: navigation.gameRules,
    icon: rules,
  },
];
export const desktopNavigationData: NavigationData[] = [
  // OptaPointsLink,
  {
    text: 'Opta Games',
    path: navigation.optaMax,
    icon: '',
    items: [
      OptaSuperBracketLink,
      OptaMaxLink,
      // OptaFourLink,
      {
        text: 'Opta Streak',
        path: navigation.optaStreak,
        icon: streak,
      },
    ],
  },
  LeaderBoardLink,
  // {
  //   text: 'Mini-Leagues',
  //   path: navigation.miniLeagues,
  //   icon: leader,
  // },
  {
    text: 'Fixtures',
    path: navigation.fixtures,
    icon: fix,
  },
  {
    text: 'Game Details',
    path: navigation.preview,
    icon: '',
    items: [
      {
        text: 'How to Play',
        path: navigation.preview,
        icon: how,
      },
      {
        text: 'Game Rules',
        path: navigation.gameRules,
        icon: rules,
      },
    ],
  },
];
