import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    color: 'absoluteWhite',
    textAlign: 'center',
    pt: '60px',
    lineHeight: '130%',
    pb: '8px',
    fontSize: '19px',
    fontWeight: '700',
  },
  body: {
    pt: '0',
    textAlign: 'center',
    color: 'TextSecondaryText',
    fontWeight: '400',
    fontSize: '14px',
  },
  footer: {
    justifyContent: 'center',
    flexDirection: 'column',
    pt: '20px',
    gap: '8px',
  },
  overlay: {
    bg: 'modal.overlayBg',
    backdropFilter: 'blur(8px)',
  },
  dialog: {
    borderRadius: '8px',
    bg: 'GradientBlue',
    boxShadow: '0px 4px 28px 0px #00000040',
  },
  content: {
    maxW: '600px',
  },
});

// Styles for mobile
const sm = defineStyle({
  dialog: {
    borderRadius: '16px 16px 0 0',
    width: '100vw',
    marginBottom: '0',
  },
  dialogContainer: {
    alignItems: 'flex-end',
  },
});

// Styles for desktop
const md = defineStyle({
  dialog: {
    borderRadius: '8px',
    width: '100%',
    maxW: '600px',
    padding: '20px',
  },
});

const bottom = definePartsStyle({
  dialog: {
    marginBottom: '0',
    borderRadius: '16px 16px 0 0',
  },
  dialogContainer: {
    alignItems: 'flex-end',
  },
  footer: {
    pb: '70px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    sm: definePartsStyle(sm),
    md: definePartsStyle(md),
  },
  variants: { bottom },
});
