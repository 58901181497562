import React from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useTheme,
  Text,
  Image,
  IconButton,
} from '@chakra-ui/react';

import cross from '@/modules/Navigation/assets/close-sidebar.svg';
import { NavigationItem } from '@/modules/Navigation/components/NavigationItem';
import { mobileNavigationData } from '@/modules/Navigation/helpers/navigation.mock';
import { useNavigation } from '@/modules/Navigation/useNavigation';

interface MobileNavigationProps {
  isOpen: boolean;
  onClose(): void;
}
export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const { handleNavigate } = useNavigation();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={'left'}>
      <DrawerOverlay />
      <DrawerContent bg='sidebarGradient' px='16px'>
        <DrawerHeader borderBottomWidth='1px' pt='24px' px='0'>
          <IconButton
            aria-label='close-sidebar'
            h='auto'
            icon={<Image alt='close' h='20px' src={cross} w='20px' />}
            mb='40px'
            minH='auto'
            minW='auto'
            onClick={onClose}
            variant='unstyled'
            w='fit-content'
          />
          <Text fontSize='18px' fontWeight='700'>
            Menu
          </Text>
        </DrawerHeader>
        <DrawerBody
          display='flex'
          flexDirection='column'
          gap='16px'
          px='0'
          py='40px'
        >
          {mobileNavigationData.map((menuItem, index) => (
            <NavigationItem
              icon={menuItem.icon}
              key={index}
              menuItems={menuItem?.items}
              onNavigate={handleNavigate}
              path={menuItem.path}
              text={menuItem.text}
              theme={theme}
            />
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
