import { Flex, Image, useTheme } from '@chakra-ui/react';

import { useSettings } from '@/hooks/useSettings.hook';

import leftImage from './assets/left-image.png';
import mainLogo from './assets/main-logo.png';
import rightImage from './assets/right-image.png';

export const MainBanner = () => {
  const { isDesktop } = useSettings();
  const theme = useTheme();

  if (!isDesktop) return null;

  return (
    <Flex
      alignItems='center'
      background={theme.colors.mainGradient}
      height='90px'
      justifyContent='space-between'
    >
      <Flex justifyContent='flex-start' width='400px'>
        <Image height='90px' src={leftImage} />
      </Flex>
      <Image height='46px' src={mainLogo} width='265px' />
      <Flex justifyContent='flex-end' width='400px'>
        <Image height='90px' src={rightImage} />
      </Flex>
    </Flex>
  );
};
