export enum RoutesEnum {
  auth = '/auth',
  bracket = '/bracket',
  fixture = '/fixture',
  root = '/',
  home = '/home',
  leaderboard = '/leaderboard/:id',
  help = '/help',
  prizes = '/prizes',
  profile = '/profile',
  leagues = '/leagues',
  leaderboards = '/leaderboards',
  login = '/login',

  activate = '/activate',
  howToPlay = '/how-to-play',
  lobby = '/lobby',
  optamax = '/optamax',
  play = '/play',
  player_rating = '/player_rating',
  preview = '/preview',
  registration = '/registration',
  rules = '/rules',
}
