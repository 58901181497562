import { Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { Navigation } from '@/modules/Navigation';

import DiagramInteractionButtons from '@/components/DiagramInteractionButtons/index';

import { useSettings } from '@/hooks/useSettings.hook';

export const Layout = () => {
  const { isMobile } = useSettings();
  return (
    <>
      <Navigation />

      <Container
        bgColor='valdreBlue'
        borderRadius={isMobile ? '0' : '8px'}
        boxShadow={isMobile ? 'none' : 'blueShadow'}
        maxW='900px'
        mb={isMobile ? '0' : '30px'}
        mt={isMobile ? 0 : '30px'}
        px='0'
      >
        {isMobile && <DiagramInteractionButtons variant='mobile' />}
        <Outlet />
      </Container>
    </>
  );
};
