import React, { ReactNode } from 'react';

import { Box, Flex, Image, useMediaQuery, useTheme } from '@chakra-ui/react';

import LogoDsk from '@/modules/Navigation/assets/Opta LogosDesk.svg';
import Logo from '@/modules/Navigation/assets/Opta-Max_Secondary-Logo.svg';
import PlayerRatingLogo from '@/modules/Navigation/assets/Opta_Logo_Secondary_02.svg';
import Menu from '@/modules/Navigation/assets/menu-02.svg';
import { MobileNavigationLayout } from '@/modules/Navigation/components/MobileNavigationLayout';
import { getNavigationConfig } from '@/modules/Navigation/utils';

import { useSettings } from '@/hooks/useSettings.hook';

type Props = {
  pathname: string;
  profile: ReactNode;
  onToggleSidebarMenu: () => void;
};

export const MobileNavigationRenderer: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { pathname, profile, onToggleSidebarMenu } = props;

  const { isMobile } = useSettings();

  // TODO temporary solution
  const [isAuthMenuAvailable] = useMediaQuery('(min-width: 1100px)');

  // TODO temporary solution
  const [isPreviewMenuAvailable] = useMediaQuery('(min-width: 850px)');

  const {
    isOptaMax,
    isPlayerRating,
    isGameRules,
    isAuth,
    isFixture,
    isLeaderboard,
    isLeagues,
    isProfile,
    isPreview,
  } = getNavigationConfig(pathname);

  if (isOptaMax) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />

        <Image
          alt='logo-icon'
          src={Logo}
          width={isMobile ? '170px' : '209px'}
        />

        {profile}
      </MobileNavigationLayout>
    );
  }

  if (isPlayerRating) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Flex alignItems='center'>
          <Image
            alt='menu-icon'
            cursor='pointer'
            marginRight='12px'
            onClick={onToggleSidebarMenu}
            src={Menu}
          />
          <Image alt='logo-icon' src={PlayerRatingLogo} width='60.4px' />
        </Flex>

        {profile}
      </MobileNavigationLayout>
    );
  }

  if (isGameRules) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />

        <Image
          alt='logo-icon'
          src={Logo}
          width={isMobile ? '170px' : '209px'}
        />

        <Box width='24px' />
      </MobileNavigationLayout>
    );
  }

  if (isAuth) {
    if (!isAuthMenuAvailable) {
      return null;
    }

    return (
      <Flex
        alignItems='center'
        background={theme.colors.authBg}
        borderBottom='0.2px solid white'
        height='85px'
        justifyContent='space-between'
        padding={isMobile ? '0 24px' : '0 48px'}
      >
        <Image
          alt='icon'
          cursor='pointer'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />
        <Image alt='logo' src={LogoDsk} />
        <Box width='24px' />
      </Flex>
    );
  }

  if (isFixture) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />
        <Box fontSize='20px' fontWeight='700'>
          Fixtures
        </Box>
        <Box width='24px' />
      </MobileNavigationLayout>
    );
  }

  if (isLeaderboard) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />
        <Box fontSize='20px' fontWeight='700'>
          Leaderboards
        </Box>
        <Box width='24px' />
      </MobileNavigationLayout>
    );
  }

  if (isLeagues) {
    return (
      <Flex
        alignItems='center'
        background={isMobile ? theme.colors.navigationGradient : 'transparent'}
        height={isMobile ? '60px' : '90px'}
        padding={isMobile ? '0 24px' : '0 48px'}
      >
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />
      </Flex>
    );
  }

  if (isProfile) {
    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          cursor='pointer'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />
        <Box fontSize='20px' fontWeight='700'>
          My Opta
        </Box>
        <Box width='24px' />
      </MobileNavigationLayout>
    );
  }

  if (isPreview) {
    if (!isPreviewMenuAvailable) {
      return null;
    }

    return (
      <MobileNavigationLayout isMobile={isMobile} theme={theme}>
        <Image
          alt='menu-icon'
          marginRight='12px'
          onClick={onToggleSidebarMenu}
          src={Menu}
        />

        <Image
          alt='logo-icon'
          src={LogoDsk}
          width={isMobile ? '170px' : '280px'}
        />

        <Box width='24px' />
      </MobileNavigationLayout>
    );
  }

  return <></>;
};
