import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  useTheme,
} from '@chakra-ui/react';

import { NavigationData } from '@/modules/Navigation/types';
import { getIsNavigationItemActive } from '@/modules/Navigation/utils';

type Props = {
  text: string;
  pathname: string;
  navigationItems: NavigationData[];
  onNavigate: (path: string) => void;
  isActive: boolean;
  icon: string | undefined;
};
export const NavigationMobileDropdown: React.FC<Props> = ({
  text,
  onNavigate,
  isActive,
  navigationItems,
  pathname,
  icon,
}) => {
  const theme = useTheme();
  return (
    <Accordion allowToggle>
      <AccordionItem background={'transparent'} border='0'>
        <h2>
          <AccordionButton
            _hover={{ width: '100%', background: 'transparent' }}
            background={'transparent'}
            borderRadius='4px'
            height='fit-content'
            padding='4px 8px'
            px='0'
            py='4px'
            textTransform='none'
            w='100%'
          >
            <Flex
              alignItems='center'
              borderRadius='50%'
              height='32px'
              justifyContent='center'
              mr='12px'
              sx={{ background: theme.colors.mainGradient }}
              width='32px'
            >
              {icon && <Image height='14px' src={icon} width='14px' />}
            </Flex>
            <Box
              as='span'
              color={isActive ? 'white' : 'rgba(255,255,255,0.5)'}
              flex='1'
              fontSize='16px'
              fontWeight='700'
              textAlign='left'
            >
              {text}
            </Box>
          </AccordionButton>
        </h2>
        <AccordionPanel pb='0' pl='45px'>
          {navigationItems.map((item, index) => {
            const isItemActive: boolean = getIsNavigationItemActive(
              pathname,
              item.path,
            );
            return (
              <Box
                borderBottom='0.2px solid'
                borderColor='white'
                className='navigation-menu-item'
                cursor='pointer'
                fontSize='16px'
                fontWeight={isItemActive ? 'bold' : '300'}
                key={index}
                onClick={() => onNavigate(item.path)}
                padding='12px 0px'
              >
                {item.text}
              </Box>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
