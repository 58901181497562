import * as React from 'react';
import { FC, ReactNode, useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import i18n from '@/i18n';

import { SignInModal } from '@/components/Modals/SignInModal/SignInModal';

import { PageLoader } from '@/elements/PageLoader';

import { SearchParamsKeysEnum } from '@/enums/searchParamsKey.enum';

import { useJWT } from '@/hooks/useJWT.hook';

import { useGenerateJWTTokenMutation } from '@/services/api.service';

type AuthContextType = {
  isSignInOpen: boolean;
  onSignInOpen(): void;
  onSignInClose(): void;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

const AuthContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setJWT } = useJWT();
  const [triggerGetJWT, { isLoading }] = useGenerateJWTTokenMutation();

  const lang = searchParams.get(SearchParamsKeysEnum.lang);
  const token = searchParams.get(SearchParamsKeysEnum.token);

  const {
    isOpen: isSignInOpen,
    onOpen: onSignInOpen,
    onClose: onSignInClose,
  } = useDisclosure();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      searchParams.delete(SearchParamsKeysEnum.lang);
    }

    if (token) {
      setJWT(token);
      searchParams.delete(SearchParamsKeysEnum.token);
      setSearchParams(searchParams);
    }
  }, [lang, searchParams, setJWT, setSearchParams, token]);

  return (
    <AuthContext.Provider value={{ isSignInOpen, onSignInOpen, onSignInClose }}>
      {isLoading && <PageLoader />}
      {children}
      <SignInModal isOpen={isSignInOpen} onClose={onSignInClose} />
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
