import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompetitionSlotsEnum } from '@/enums/competitionSlots.enum';
import { ConferenceAlignEnum } from '@/enums/conferenceAlign.enum';
import { MatchSlotsEnum } from '@/enums/matchSlots.enum';

import { StoreConferenceType } from '@/types/custom';
import {
  BracketSettingType,
  MatchSelectionType,
  MatchType,
  UserSelectionType,
} from '@/types/domain';

import {
  isAwayTeamInSelections,
  isHomeTeamInSelections,
  nullifyAwayTeamMatch,
  nullifyHomeTeamMatch,
  resetNeededMatchInMatchesByMatchId,
  setNewBodyForAwayTeamMatch,
  setNewBodyForHomeTeamMatch,
  setTeamInNeededSlotNextMatch,
  SlotsArr,
} from '@/utils/bracket.util';
import { isExist } from '@/utils/common.util';

export type SetCompetitionsGridType = MatchType[];
export type SetSelectionType = Pick<
  MatchSelectionType,
  'match_id' | 'winner_team_id'
>;
export type ChangeTeamInNextRoundsActionType = SetSelectionType & {
  foundMatchSelection: Partial<MatchSelectionType> | undefined;
};

export interface MatchesInitialStateType {
  selectedConferenceId: number | null;
  selectedRoundId: number | null;
  selections: Partial<MatchSelectionType>[];
  initialSelections: MatchSelectionType[];
  conferences: StoreConferenceType[];
  initialConferences: StoreConferenceType[];
  matches: MatchType[];
  initialMatches: MatchType[];
  tabIndex: number;
  lastFilledSlot:
    | {
        conference_id: number;
        slot: string;
      }
    | undefined;
  userSelectionsInfo: Omit<UserSelectionType, 'selection_matches'> | undefined;
  tiebreakerValue: string;
  bracketSetting: BracketSettingType | undefined;
  isBracketEnabled: boolean;
}

const initialState: MatchesInitialStateType = {
  selectedConferenceId: null,
  selectedRoundId: null,
  selections: [],
  initialSelections: [],
  conferences: [],
  initialConferences: [],
  matches: [],
  initialMatches: [],
  tabIndex: 0,
  lastFilledSlot: undefined,
  userSelectionsInfo: undefined,
  tiebreakerValue: '',
  bracketSetting: undefined,
  isBracketEnabled: true,
};

const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    SET_SELECTED_CONFERENCE_ID(
      state,
      action: PayloadAction<MatchesInitialStateType['selectedConferenceId']>,
    ) {
      state.selectedConferenceId = action.payload;
    },
    SET_SELECTED_ROUND_ID(
      state,
      action: PayloadAction<MatchesInitialStateType['selectedRoundId']>,
    ) {
      state.selectedRoundId = action.payload;
    },
    SET_INITIAL_SELECTIONS(
      state,
      action: PayloadAction<MatchesInitialStateType['initialSelections']>,
    ) {
      state.selections = action.payload;
      state.initialSelections = action.payload;
    },
    SET_USER_SELECTIONS_INFO(
      state,
      action: PayloadAction<MatchesInitialStateType['userSelectionsInfo']>,
    ) {
      state.userSelectionsInfo = action.payload;
    },
    SET_CONFERENCES(
      state,
      action: PayloadAction<MatchesInitialStateType['conferences']>,
    ) {
      state.conferences = action.payload;
      state.initialConferences = action.payload;
    },
    SET_MATCHES(
      state,
      action: PayloadAction<MatchesInitialStateType['matches']>,
    ) {
      state.matches = action.payload;
      state.initialMatches = action.payload;
    },
    SET_TAB_INDEX(
      state,
      action: PayloadAction<MatchesInitialStateType['tabIndex']>,
    ) {
      state.tabIndex = action.payload;
    },
    SET_MATCH(state, action: PayloadAction<MatchType>) {
      const { match_id } = action.payload;
      state.matches = state.matches.map((x) =>
        x.match_id === match_id ? action.payload : x,
      );
    },
    CLEAR_BRACKET(state) {
      state.selections = state.initialSelections;
      state.conferences = state.initialConferences;
      state.matches = state.initialMatches;
      state.lastFilledSlot = undefined;
      state.tiebreakerValue = '';
    },
    SET_TIEBREAKER_VALUE(
      state,
      action: PayloadAction<MatchesInitialStateType['tiebreakerValue']>,
    ) {
      state.tiebreakerValue = action.payload;
    },
    SET_BRACKET_SETTING(
      state,
      action: PayloadAction<MatchesInitialStateType['bracketSetting']>,
    ) {
      state.bracketSetting = action.payload;
    },
    SET_IS_BRACKET_ENABLED(
      state,
      action: PayloadAction<MatchesInitialStateType['isBracketEnabled']>,
    ) {
      state.isBracketEnabled = action.payload;
    },
    SET_LAST_FILLED_SLOT(
      state,
      action: PayloadAction<{ slot: string; conference_id?: number }>,
    ) {
      state.lastFilledSlot = {
        conference_id:
          action.payload?.conference_id ||
          (state.selectedConferenceId as number),
        slot: action.payload?.slot,
      };
    },
    DELETE_TEAM_FROM_MATCH(state, action: PayloadAction<SetSelectionType>) {
      const { match_id, winner_team_id } = action.payload;

      const foundMatch = state.matches.find((x) => x.match_id === match_id);
      const currSlot = foundMatch?.slot?.[0] as string;

      // This needs to remove selected team from all matches array
      state.matches = state.matches.map((x) => {
        if (x?.slot?.[0] < currSlot) {
          if (isHomeTeamInSelections(winner_team_id, x)) {
            return nullifyHomeTeamMatch(x);
          } else if (isAwayTeamInSelections(winner_team_id, x)) {
            return nullifyAwayTeamMatch(x);
          } else return x;
        }
        return x;
      });

      // This needs to remove selected team from all next rounds in conferences
      state.conferences.map((conference, idx) => {
        Object.entries(conference?.rounds || {}).map(([key, value]) => {
          const isClickedTeamExistsInMatch = value.some(
            (x) =>
              isHomeTeamInSelections(winner_team_id, x) ||
              isAwayTeamInSelections(winner_team_id, x),
          );
          if (key < currSlot && isClickedTeamExistsInMatch) {
            state.conferences[idx].rounds[key as CompetitionSlotsEnum] =
              value.map((x) => {
                if (isHomeTeamInSelections(winner_team_id, x)) {
                  return nullifyHomeTeamMatch(x);
                } else if (isAwayTeamInSelections(winner_team_id, x)) {
                  return nullifyAwayTeamMatch(x);
                } else return x;
              });
          }
        });
      });

      // This needs to remove selected team from all selections in next rounds
      state.selections = state.selections
        .map((x) => {
          const match = state.matches.find((y) => y.match_id === x.match_id);
          if (
            match?.slot &&
            match?.slot?.[0] < currSlot &&
            x.winner_team_id === winner_team_id
          )
            return;
          return x;
        })
        .filter(Boolean) as Partial<MatchSelectionType>[];
    },
    SET_TEAM_INTO_NEXT_ROUND(state, action: PayloadAction<SetSelectionType>) {
      const { match_id, winner_team_id } = action.payload;
      const foundMatch = state.matches.find((x) => x.match_id === match_id);
      //Find first letter of slot
      const slotOfFoundMatch = foundMatch?.slot?.[0];
      //Find index of current slot in alphabet arr
      const idxOfCurrSlot = SlotsArr.findIndex((x) => x === slotOfFoundMatch);
      //Extract number from current slot position
      const posOfCurrSlot = Number(foundMatch?.slot?.match(/(\d+)/)?.[0]);

      //Find prev slot letter for selected team
      const prevSlot = idxOfCurrSlot >= 0 ? SlotsArr[idxOfCurrSlot - 1] : null;
      //Every prev letter in grid has (current letter / 2) matches;
      //Match.ceil is needed here to be sure that as exp F:31 will move to the E:16.
      const posOfPrevSlot = Math.ceil(posOfCurrSlot / 2);

      if (prevSlot) {
        state.conferences = state.conferences.map((x) => {
          const lastFilledSlotInConference = Object.keys(x.rounds)
            .map((k) =>
              x.rounds[k as CompetitionSlotsEnum]?.length ? k : undefined,
            )
            .find(Boolean);

          const foundNextStageConference = state.conferences.find(
            (conf) =>
              Object.keys(conf?.rounds).filter(
                (round) =>
                  conf?.rounds[round as CompetitionSlotsEnum].length &&
                  lastFilledSlotInConference &&
                  round < lastFilledSlotInConference,
              ).length,
          );
          const nextStageConfIdx = state.conferences.findIndex(
            (c) => c.conference_id === foundNextStageConference?.conference_id,
          );

          if (
            lastFilledSlotInConference &&
            prevSlot < lastFilledSlotInConference &&
            foundNextStageConference
          ) {
            const conferenceRounds = foundNextStageConference.rounds || {};
            for (const key in conferenceRounds) {
              if (key === prevSlot) {
                conferenceRounds[key as CompetitionSlotsEnum] =
                  conferenceRounds[key as CompetitionSlotsEnum].map((match) => {
                    if (match.slot === `${prevSlot}:${posOfPrevSlot}`) {
                      const preparedBody = setTeamInNeededSlotNextMatch({
                        foundMatch,
                        winner_team_id,
                        posOfCurrSlot,
                      });
                      matchesSlice.caseReducers.SET_MATCH(state, {
                        payload: {
                          ...match,
                          ...preparedBody,
                          conference_id:
                            foundNextStageConference?.conference_id,
                        },
                        type: 'matches/SET_MATCH',
                      });
                      return {
                        ...match,
                        ...preparedBody,
                        conference_id: foundNextStageConference?.conference_id,
                      };
                    }
                    return match;
                  });
              }
            }

            state.conferences[nextStageConfIdx].rounds = conferenceRounds;
            return x;
          } else if (x.conference_id === state.selectedConferenceId) {
            const conferenceRounds = x.rounds || {};
            for (const key in conferenceRounds) {
              if (key === prevSlot) {
                conferenceRounds[key as CompetitionSlotsEnum] =
                  conferenceRounds[key as CompetitionSlotsEnum].map((match) => {
                    if (match.slot === `${prevSlot}:${posOfPrevSlot}`) {
                      const preparedBody = setTeamInNeededSlotNextMatch({
                        foundMatch,
                        winner_team_id,
                        posOfCurrSlot,
                      });
                      matchesSlice.caseReducers.SET_MATCH(state, {
                        payload: { ...match, ...preparedBody },
                        type: 'matches/SET_MATCH',
                      });
                      return { ...match, ...preparedBody };
                    }
                    return match;
                  });
                matchesSlice.caseReducers.SET_LAST_FILLED_SLOT(state, {
                  payload: { slot: key },
                  type: 'matches/SET_LAST_FILLED_SLOT',
                });
              }
            }
            return { ...x, rounds: conferenceRounds };
          }
          return x;
        });
      }
    },
    CHANGE_TEAM_IN_NEXT_ROUNDS(
      state,
      action: PayloadAction<ChangeTeamInNextRoundsActionType>,
    ) {
      const { match_id, winner_team_id, foundMatchSelection } = action.payload;
      const foundMatch = state.matches.find((x) => x.match_id === match_id);
      const currSlot = foundMatch?.slot?.[0] as string;
      // This needs to remove selected team from all selections in next rounds
      state.selections = [...state.selections]
        .map((x) => {
          const match = state.matches.find((y) => y.match_id === x.match_id);
          if (x?.match_id === match_id) return { ...x, winner_team_id };
          if (
            match?.slot &&
            match?.slot?.[0] < currSlot &&
            x.winner_team_id === foundMatchSelection?.winner_team_id
          )
            return;
          return x;
        })
        .filter(Boolean) as Partial<MatchSelectionType>[];

      // This needs to remove selected team from all next rounds in conferences
      state.conferences.map((conference, idx) => {
        Object.entries(conference?.rounds || {}).map(([key, value]) => {
          const isClickedTeamExistsInMatch = value.some(
            (x) =>
              isHomeTeamInSelections(foundMatchSelection?.winner_team_id, x) ||
              isAwayTeamInSelections(foundMatchSelection?.winner_team_id, x),
          );
          if (key < currSlot && isClickedTeamExistsInMatch) {
            state.conferences[idx].rounds[key as CompetitionSlotsEnum] =
              value.map((x) => {
                if (
                  isHomeTeamInSelections(foundMatchSelection?.winner_team_id, x)
                ) {
                  return nullifyHomeTeamMatch(x);
                } else if (
                  isAwayTeamInSelections(foundMatchSelection?.winner_team_id, x)
                ) {
                  return nullifyAwayTeamMatch(x);
                } else {
                  return x;
                }
              });
          }
        });
      });
    },
    SET_SELECTION(state, action: PayloadAction<SetSelectionType>) {
      const { match_id, winner_team_id } = action.payload;
      const selections = [...state.selections];
      const foundSelection = selections?.find(
        (x) => x.match_id === match_id && x.winner_team_id === winner_team_id,
      );

      if (foundSelection) {
        state.selections = selections?.filter(
          (x) => x.match_id !== foundSelection?.match_id,
        );
        matchesSlice.caseReducers.DELETE_TEAM_FROM_MATCH(state, {
          payload: action.payload,
          type: 'matches/DELETE_TEAM_FROM_MATCH',
        });
      } else {
        const foundMatchSelection = selections?.find(
          (x) => x?.match_id === match_id,
        );

        if (foundMatchSelection && isExist(winner_team_id)) {
          matchesSlice.caseReducers.SET_TEAM_INTO_NEXT_ROUND(state, {
            ...action,
            type: 'matches/SET_TEAM_INTO_NEXT_ROUND',
          });
          matchesSlice.caseReducers.CHANGE_TEAM_IN_NEXT_ROUNDS(state, {
            payload: { ...action.payload, foundMatchSelection },
            type: 'matches/CHANGE_TEAM_IN_NEXT_ROUNDS',
          });
          return;
        }

        if (isExist(winner_team_id)) {
          state.selections = [...selections, { match_id, winner_team_id }];
          matchesSlice.caseReducers.SET_TEAM_INTO_NEXT_ROUND(state, {
            ...action,
            type: 'matches/SET_TEAM_INTO_NEXT_ROUND',
          });
        }
        return;
      }
    },
    //This needs to be a separate func to be sure that final conference will always fill after all other conferences, no matter final conference position in the conferences array
    FILL_FINAL_CONFERENCE_BY_PREDEFINED_SELECTIONS(state) {
      const finalConferenceIdx = state.conferences.findIndex(
        (x) => x.rounds['A'].length,
      );
      const conferenceRounds = {
        ...state.conferences[finalConferenceIdx].rounds,
      };
      Object.keys(conferenceRounds)
        .reverse()
        .map((roundKey) => {
          const indexOfCurrentRound = SlotsArr.findIndex((x) => x === roundKey);
          const prevRoundKey = SlotsArr[indexOfCurrentRound + 1];

          if (conferenceRounds[roundKey as CompetitionSlotsEnum].length) {
            conferenceRounds[roundKey as CompetitionSlotsEnum] =
              conferenceRounds[roundKey as CompetitionSlotsEnum].map(
                (currRoundMatch) => {
                  const currRoundMatchSlotNum = Number(
                    currRoundMatch.slot.match(/(\d+)/)?.[0],
                  );

                  let currRoundMatchCloned = { ...currRoundMatch };

                  if (
                    state.initialSelections.find(
                      (initS) => initS.match_id === currRoundMatch.match_id,
                    )
                  ) {
                    state.matches
                      .filter((x) => x.slot?.[0] === prevRoundKey)
                      .map((matchExternal) => {
                        const matchExternalSlot = matchExternal.slot?.[0];
                        const matchExternalSlotNum = Number(
                          matchExternal.slot.match(/(\d+)/)?.[0],
                        );
                        // RM = RoundMatch
                        const isMatchExternalSlotNumEqualsToCurrRMSlotNum =
                          Math.ceil(matchExternalSlotNum / 2) ===
                          Number(currRoundMatchSlotNum);

                        if (!isMatchExternalSlotNumEqualsToCurrRMSlotNum)
                          return;

                        const matchExternalInSelections =
                          state.initialSelections.find(
                            (initS) =>
                              initS.match_id === matchExternal.match_id,
                          );

                        if (!matchExternalInSelections) return;

                        const winnerTeamIdFromSelection =
                          matchExternalInSelections?.winner_team_id;
                        const isAwayTeam = isAwayTeamInSelections(
                          winnerTeamIdFromSelection,
                          matchExternal,
                        );

                        if (
                          matchExternalSlotNum / 2 !==
                          Number(currRoundMatchSlotNum)
                        ) {
                          return (currRoundMatchCloned =
                            setNewBodyForAwayTeamMatch({
                              currentRoundMatch: currRoundMatchCloned,
                              isAwayTeam,
                              prevRoundMatch: matchExternal,
                            }));
                        }

                        return (currRoundMatchCloned =
                          setNewBodyForHomeTeamMatch({
                            currentRoundMatch: currRoundMatchCloned,
                            isAwayTeam,
                            prevRoundMatch: matchExternal,
                          }));
                      });

                    state.matches = resetNeededMatchInMatchesByMatchId(
                      state.matches,
                      currRoundMatchCloned,
                    );

                    return currRoundMatchCloned;
                  }
                  state.matches = resetNeededMatchInMatchesByMatchId(
                    state.matches,
                    currRoundMatchCloned,
                  );

                  return currRoundMatchCloned;
                },
              );
          }
        });
      if (finalConferenceIdx >= 0) {
        state.conferences[finalConferenceIdx].rounds = conferenceRounds;
      }
    },

    FILL_MATCHES_BY_PREDEFINED_SELECTIONS(state) {
      state.conferences.map((conf) => {
        const currentConferenceIdx = state.conferences.findIndex(
          (x) => x.conference_id === conf.conference_id,
        );

        const conferenceRounds = {
          ...state.conferences[currentConferenceIdx]?.rounds,
        };

        Object.keys(conferenceRounds).map(() => {
          // A,B,C,D
          for (const roundKey in conferenceRounds) {
            if (conferenceRounds[roundKey as CompetitionSlotsEnum].length) {
              const indexOfCurrentRound = SlotsArr.findIndex(
                (x) => x === roundKey,
              );
              const prevRoundKey = SlotsArr[indexOfCurrentRound + 1];

              conferenceRounds[roundKey as CompetitionSlotsEnum] =
                conferenceRounds[roundKey as CompetitionSlotsEnum].map(
                  (currRoundMatch) => {
                    const currRoundMatchSlotNum = Number(
                      currRoundMatch.slot.match(/(\d+)/)?.[0],
                    );

                    let currRoundMatchCloned = { ...currRoundMatch };

                    const isInitialSelectionExistInCurrentRoundMatch =
                      state.initialSelections.find(
                        (initS) => initS.match_id === currRoundMatch.match_id,
                      );

                    if (!isInitialSelectionExistInCurrentRoundMatch)
                      return currRoundMatchCloned;

                    conferenceRounds[prevRoundKey as CompetitionSlotsEnum].map(
                      (prevRoundMatch) => {
                        const prevRoundMatchSlotNum = Number(
                          prevRoundMatch.slot.match(/(\d+)/)?.[0],
                        );
                        // RM = RoundMatch
                        const isPrevRMSlotNumEqualsToCurrRMSlotNum =
                          Math.ceil(prevRoundMatchSlotNum / 2) ===
                          Number(currRoundMatchSlotNum);

                        if (isPrevRMSlotNumEqualsToCurrRMSlotNum) {
                          const prevRMInSelections =
                            state.initialSelections.find(
                              (initS) =>
                                initS.match_id === prevRoundMatch.match_id,
                            );
                          if (prevRMInSelections) {
                            const winnerTeamIdFromSelection =
                              prevRMInSelections?.winner_team_id;
                            const isAwayTeam = isAwayTeamInSelections(
                              winnerTeamIdFromSelection,
                              prevRoundMatch,
                            );
                            if (
                              prevRoundMatchSlotNum / 2 !==
                              Number(currRoundMatchSlotNum)
                            ) {
                              currRoundMatchCloned = setNewBodyForAwayTeamMatch(
                                {
                                  currentRoundMatch: currRoundMatchCloned,
                                  isAwayTeam,
                                  prevRoundMatch: prevRoundMatch,
                                },
                              );
                              return;
                            }
                            currRoundMatchCloned = setNewBodyForHomeTeamMatch({
                              currentRoundMatch: currRoundMatchCloned,
                              isAwayTeam,
                              prevRoundMatch: prevRoundMatch,
                            });
                            return;
                          }
                          return;
                        }
                        return;
                      },
                    );
                    state.matches = resetNeededMatchInMatchesByMatchId(
                      state.matches,
                      currRoundMatchCloned,
                    );
                    return currRoundMatchCloned;

                    state.matches = resetNeededMatchInMatchesByMatchId(
                      state.matches,
                      currRoundMatchCloned,
                    );

                    return currRoundMatchCloned;
                  },
                );
            }
          }
        });

        if (currentConferenceIdx >= 0) {
          state.conferences[currentConferenceIdx].rounds = conferenceRounds;
        }
      });

      matchesSlice.caseReducers.FILL_FINAL_CONFERENCE_BY_PREDEFINED_SELECTIONS(
        state,
      );
    },
    RANDOMIZE_CONFERENCE_SELECTIONS(
      state,
      action: PayloadAction<{
        conferenceRounds: StoreConferenceType['rounds'];
      }>,
    ) {
      const { conferenceRounds } = action.payload;
      let selections = [...state.selections];
      // set selections
      Object.keys(conferenceRounds)
        .reverse()
        .filter((k) => conferenceRounds[k as CompetitionSlotsEnum]?.length)
        .map((roundKey, idx, roundsKeys) => {
          const indexOfCurrentRound = SlotsArr.findIndex((x) => x === roundKey);

          const prevRoundKey = SlotsArr[indexOfCurrentRound - 1];
          conferenceRounds[roundKey as CompetitionSlotsEnum].map(
            (currRoundMatch) => {
              if (currRoundMatch.home_team_id || currRoundMatch.away_team_id) {
                if (idx === roundsKeys?.length - 1) {
                  const isSelectionExists = selections.some(
                    (x) => x.match_id === currRoundMatch.match_id,
                  );
                  //   //Random logic val < 0.5 === away team, val > 0.5 && val <= 1 === home team
                  const isAwayTeam = Math.random() < 0.5;
                  if (isSelectionExists) {
                    selections = selections.map((s) =>
                      s.match_id === currRoundMatch.match_id
                        ? {
                            ...s,
                            winner_team_id: isAwayTeam
                              ? currRoundMatch.away_team_id
                              : currRoundMatch.home_team_id,
                          }
                        : s,
                    );
                  } else {
                    selections = [
                      ...selections,
                      {
                        match_id: currRoundMatch.match_id,
                        winner_team_id: isAwayTeam
                          ? currRoundMatch.away_team_id
                          : currRoundMatch.home_team_id,
                      },
                    ];
                  }
                } else {
                  const currRoundMatchSlotNum = Number(
                    currRoundMatch.slot.match(/(\d+)/)?.[0],
                  );
                  const foundSelection = selections.find(
                    (x) => x.match_id === currRoundMatch.match_id,
                  );
                  if (
                    conferenceRounds[prevRoundKey as CompetitionSlotsEnum]
                      ?.length
                  ) {
                    conferenceRounds[prevRoundKey as CompetitionSlotsEnum].map(
                      (prevRoundMatch) => {
                        const prevRoundMatchSlotNum = Number(
                          prevRoundMatch.slot.match(/(\d+)/)?.[0],
                        );
                        // RM = RoundMatch
                        const isCurrRMSlotNumEqualsToPrevRMSlotNum =
                          Math.ceil(currRoundMatchSlotNum / 2) ===
                          Number(prevRoundMatchSlotNum);
                        if (isCurrRMSlotNumEqualsToPrevRMSlotNum) {
                          if (
                            currRoundMatchSlotNum / 2 !==
                            Number(prevRoundMatchSlotNum)
                          ) {
                            if (foundSelection) {
                              selections = selections.map((s) =>
                                s.match_id === currRoundMatch.match_id
                                  ? {
                                      ...s,
                                      winner_team_id:
                                        prevRoundMatch.away_team_id,
                                    }
                                  : s,
                              );
                              return;
                            }
                            selections = [
                              ...selections,
                              {
                                match_id: currRoundMatch.match_id,
                                winner_team_id: prevRoundMatch.away_team_id,
                              },
                            ];
                            return;
                          }
                          if (foundSelection) {
                            selections = selections.map((s) =>
                              s.match_id === currRoundMatch.match_id
                                ? {
                                    ...s,
                                    winner_team_id: prevRoundMatch.home_team_id,
                                  }
                                : s,
                            );
                            return;
                          }
                          selections = [
                            ...selections,
                            {
                              match_id: currRoundMatch.match_id,
                              winner_team_id: prevRoundMatch.home_team_id,
                            },
                          ];
                          return;
                        }
                        return;
                      },
                    );
                  }
                }
              }
            },
          );
        });
      state.selections = selections;
    },
    RANDOMIZE_MATCHES_IN_CONFERENCE(
      state,
      action: PayloadAction<{
        conference: StoreConferenceType;
      }>,
    ) {
      const { conference } = action.payload;
      const isFinalConference = conference.rounds['A'].length;
      const isSingleConference = state.conferences.length === 1;

      const currentConferenceIdx = state.conferences.findIndex(
        (x) => x.conference_id === conference.conference_id,
      );
      const conferenceRounds = { ...conference?.rounds };
      Object.keys(conferenceRounds).map(() => {
        for (const roundKey in conferenceRounds) {
          const indexOfCurrentRound = SlotsArr.findIndex((x) => x === roundKey);
          const prevRoundKey = SlotsArr[indexOfCurrentRound + 1];
          if (conferenceRounds[roundKey as CompetitionSlotsEnum]?.length) {
            conferenceRounds[roundKey as CompetitionSlotsEnum] =
              conferenceRounds[roundKey as CompetitionSlotsEnum].map(
                (currRoundMatch) => {
                  const currRoundMatchSlotNum = Number(
                    currRoundMatch.slot.match(/(\d+)/)?.[0],
                  );
                  let currRoundMatchCloned = { ...currRoundMatch };

                  // !IMPORTANT if it's one conference in app with 'A' final match we should skip checking on isFinalConference to make correct randomise and selections logic
                  const shouldMakeRandom = isSingleConference
                    ? !currRoundMatch?.away_team_id ||
                      !currRoundMatch?.home_team_id
                    : isFinalConference ||
                      !currRoundMatch?.away_team_id ||
                      !currRoundMatch?.home_team_id;

                  if (shouldMakeRandom) {
                    //Random logic val < 0.5 === away team, val > 0.5 && val <= 1 === home team
                    const isAwayTeam = Math.random() < 0.5;
                    if (
                      conferenceRounds[prevRoundKey as CompetitionSlotsEnum]
                        ?.length
                    ) {
                      conferenceRounds[
                        prevRoundKey as CompetitionSlotsEnum
                      ].map((prevRoundMatch) => {
                        const prevRoundMatchSlotNum = Number(
                          prevRoundMatch.slot.match(/(\d+)/)?.[0],
                        );
                        // RM = RoundMatch
                        const isPrevRMSlotNumEqualsToCurrRMSlotNum =
                          Math.ceil(prevRoundMatchSlotNum / 2) ===
                          Number(currRoundMatchSlotNum);
                        if (isPrevRMSlotNumEqualsToCurrRMSlotNum) {
                          if (
                            prevRoundMatchSlotNum / 2 !==
                            Number(currRoundMatchSlotNum)
                          ) {
                            currRoundMatchCloned = setNewBodyForAwayTeamMatch({
                              currentRoundMatch: currRoundMatchCloned,
                              isAwayTeam,
                              prevRoundMatch: prevRoundMatch,
                            });
                            return;
                          }
                          currRoundMatchCloned = setNewBodyForHomeTeamMatch({
                            currentRoundMatch: currRoundMatchCloned,
                            isAwayTeam,
                            prevRoundMatch: prevRoundMatch,
                          });
                          return;
                        }
                      });
                    }
                  }
                  state.matches = resetNeededMatchInMatchesByMatchId(
                    state.matches,
                    currRoundMatchCloned,
                  );
                  return currRoundMatchCloned;
                },
              );
          }
        }
      });
      if (currentConferenceIdx >= 0) {
        state.conferences[currentConferenceIdx].rounds = conferenceRounds;
      }

      matchesSlice.caseReducers.RANDOMIZE_CONFERENCE_SELECTIONS(state, {
        payload: { conferenceRounds },
        type: 'matches/RANDOMIZE_CONFERENCE_SELECTIONS',
      });
    },
    RANDOMIZE_TIEBREAKER_VALUE(state) {
      if (!state.tiebreakerValue) {
        const randomTiebreakerValue = Math.floor(Math.random() * 100) + 1;
        matchesSlice.caseReducers.SET_TIEBREAKER_VALUE(state, {
          payload: String(randomTiebreakerValue),
          type: 'matches/SET_TIEBREAKER_VALUE',
        });
      }
    },
    FILL_FINAL_CONFERENCE_BY_MATCHES(state) {
      const finalConference = state.conferences.find(
        (x) => x.rounds['A']?.length,
      );

      if (finalConference) {
        const conferenceRounds = { ...finalConference.rounds };
        Object.keys(conferenceRounds).map((roundKey) => {
          conferenceRounds[roundKey as CompetitionSlotsEnum] = conferenceRounds[
            roundKey as CompetitionSlotsEnum
          ].map((currRoundMatch) => {
            let currRoundMatchCloned = { ...currRoundMatch };

            const indexOfCurrentRound = SlotsArr.findIndex(
              (x) => x === roundKey,
            );
            const prevRoundKey = SlotsArr[indexOfCurrentRound + 1];
            const currRoundMatchSlotNum = Number(
              currRoundMatch.slot.match(/(\d+)/)?.[0],
            );

            state.matches
              .filter((x) => x.slot?.[0] === prevRoundKey)
              .map((match) => {
                const foundMatchSelection = state.selections.find(
                  (x) => x.match_id === match.match_id,
                );

                const matchSlotNum = Number(match.slot.match(/(\d+)/)?.[0]);
                const isMatchSlotNumEqualsToCurrRMSlotNum =
                  Math.ceil(matchSlotNum / 2) === Number(currRoundMatchSlotNum);
                if (foundMatchSelection) {
                  if (isMatchSlotNumEqualsToCurrRMSlotNum) {
                    const isAwayTeam = isAwayTeamInSelections(
                      foundMatchSelection?.winner_team_id,
                      match,
                    );
                    if (matchSlotNum / 2 !== Number(currRoundMatchSlotNum)) {
                      currRoundMatchCloned = setNewBodyForAwayTeamMatch({
                        currentRoundMatch: currRoundMatchCloned,
                        isAwayTeam,
                        prevRoundMatch: match,
                      });
                      return;
                    }
                    currRoundMatchCloned = setNewBodyForHomeTeamMatch({
                      currentRoundMatch: currRoundMatchCloned,
                      isAwayTeam,
                      prevRoundMatch: match,
                    });
                    return;
                  }
                }
              });
            state.matches = resetNeededMatchInMatchesByMatchId(
              state.matches,
              currRoundMatchCloned,
            );
            return currRoundMatchCloned;
          });
        });

        matchesSlice.caseReducers.RANDOMIZE_MATCHES_IN_CONFERENCE(state, {
          payload: {
            conference: { ...finalConference, rounds: conferenceRounds },
          },
          type: 'matches/RANDOMIZE_MATCHES_IN_CONFERENCE',
        });

        matchesSlice.caseReducers.RANDOMIZE_TIEBREAKER_VALUE(state);
      }
    },
    RANDOMIZE_CURRENT_CONFERENCE(state) {
      const selectedConference = state.conferences.find(
        (x) => x.conference_id === state.selectedConferenceId,
      );
      if (selectedConference) {
        const isFinalConference = !!selectedConference.rounds['A'].length;

        matchesSlice.caseReducers.RANDOMIZE_MATCHES_IN_CONFERENCE(state, {
          payload: { conference: selectedConference },
          type: 'matches/RANDOMIZE_MATCHES_IN_CONFERENCE',
        });
        const keyWithLastMatch = Object.keys(selectedConference.rounds).find(
          (x) =>
            selectedConference.rounds[x as CompetitionSlotsEnum].length === 1,
        );
        if (keyWithLastMatch) {
          const lastMatch =
            selectedConference.rounds[
              keyWithLastMatch as CompetitionSlotsEnum
            ].find(Boolean);
          const lastMatchInSelections = state.selections.find(
            (x) => x.match_id === lastMatch?.match_id,
          );
          if (lastMatchInSelections) {
            matchesSlice.caseReducers.SET_TEAM_INTO_NEXT_ROUND(state, {
              payload: {
                match_id: lastMatchInSelections?.match_id as number,
                winner_team_id: lastMatchInSelections?.winner_team_id as number,
              },
              type: 'matches/SET_TEAM_INTO_NEXT_ROUND',
            });
          }
        }
        if (isFinalConference) {
          matchesSlice.caseReducers.RANDOMIZE_TIEBREAKER_VALUE(state);
        }
      }
    },
    RANDOMIZE_BRACKET(state) {
      state.conferences
        .filter((x) => !x.rounds['A'].length)
        .map((conference) => {
          matchesSlice.caseReducers.RANDOMIZE_MATCHES_IN_CONFERENCE(state, {
            payload: { conference },
            type: 'matches/RANDOMIZE_MATCHES_IN_CONFERENCE',
          });
        });

      matchesSlice.caseReducers.FILL_FINAL_CONFERENCE_BY_MATCHES(state);
    },
  },
});

export default matchesSlice.reducer;
export const {
  SET_SELECTED_CONFERENCE_ID,
  SET_SELECTED_ROUND_ID,
  SET_INITIAL_SELECTIONS,
  SET_SELECTION,
  SET_CONFERENCES,
  SET_MATCHES,
  SET_TAB_INDEX,
  SET_USER_SELECTIONS_INFO,
  FILL_MATCHES_BY_PREDEFINED_SELECTIONS,
  CLEAR_BRACKET,
  RANDOMIZE_BRACKET,
  SET_TIEBREAKER_VALUE,
  SET_BRACKET_SETTING,
  SET_IS_BRACKET_ENABLED,
  RANDOMIZE_CURRENT_CONFERENCE,
} = matchesSlice.actions;
