import React, { useMemo } from 'react';

import { Button, Flex, Image, WithCSSVar } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { NavigationDropdown } from '@/modules/Navigation/components/NavigationDropdown';
import { NavigationMobileDropdown } from '@/modules/Navigation/components/NavigationMobileDropdown';
import { NavigationData } from '@/modules/Navigation/types';
import { getIsNavigationItemActive } from '@/modules/Navigation/utils';

import { useSettings } from '@/hooks/useSettings.hook';

type Props = {
  theme: WithCSSVar<any>;
  text: string;
  path: string;
  icon: string | undefined;
  menuItems?: NavigationData[];
  onNavigate: (path: string) => void;
};

export const NavigationItem: React.FC<Props> = (props) => {
  const { theme, text, path, icon, menuItems, onNavigate } = props;

  const { pathname } = useLocation();
  const { isDesktop } = useSettings();

  const isActive: boolean = useMemo(() => {
    return getIsNavigationItemActive(pathname, path);
  }, [pathname, path]);

  const itemBgColor = useMemo(() => {
    if (isDesktop) {
      if (isActive) return theme.colors.mainGradient;
      return theme.colors.navigationBg;
    }
    return 'transparent';
  }, [
    isActive,
    isDesktop,
    theme.colors.mainGradient,
    theme.colors.navigationBg,
  ]);

  if (menuItems) {
    return isDesktop ? (
      <NavigationDropdown
        isActive={isActive}
        navigationItems={menuItems}
        onNavigate={onNavigate}
        pathname={pathname}
        text={text}
      />
    ) : (
      <NavigationMobileDropdown
        icon={icon}
        isActive={isActive}
        navigationItems={menuItems}
        onNavigate={onNavigate}
        pathname={pathname}
        text={text}
      />
    );
  }

  return (
    <Button
      _hover={{ background: isDesktop ? theme.colors.mainGradient : 'inherit' }}
      background={itemBgColor}
      borderRadius='4px'
      className='navigation-button'
      color={
        isDesktop ? 'inherit' : isActive ? 'white' : 'rgba(255,255,255,0.5)'
      }
      fontSize={isDesktop ? '14px' : '16px'}
      height='fit-content'
      justifyContent={isDesktop ? 'center' : 'flex-start'}
      leftIcon={
        <Flex
          alignItems='center'
          borderRadius='50%'
          height={isDesktop ? '28px' : '32px'}
          justifyContent={'center'}
          sx={{
            background: isDesktop
              ? isActive
                ? theme.colors.navigationBg
                : theme.colors.mainGradient
              : theme.colors.mainGradient,
            '.navigation-button:hover &': {
              background: isDesktop
                ? theme.colors.navigationBg
                : theme.colors.mainGradient,
            },
          }}
          width={isDesktop ? '28px' : '32px'}
        >
          <Image height='14px' src={icon} width='14px' />
        </Flex>
      }
      onClick={() => onNavigate(path)}
      padding={isDesktop ? '4px 8px' : '4px 0'}
      sx={{
        '.chakra-button__icon': {
          marginRight: isDesktop ? '8px' : '12px',
        },
      }}
      textTransform='none'
      width='auto'
    >
      {text}
    </Button>
  );
};
