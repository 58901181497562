import { Fragment, Suspense } from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { AppRoutes } from '@/routes';

import { Layout } from '@/components/Layout';

import { PageLoader } from '@/elements/PageLoader';

function App() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<Layout />}>
          {AppRoutes.map((route) => (
            <Fragment key={route.path}>
              <Route element={route.element} path={route.path} />
            </Fragment>
          ))}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
