import { useSettings } from '@/hooks/useSettings.hook';

import { DesktopNavigation } from './components/DesktopNavigation';
import { MobileNavigation } from './containers/MobileNavigation';

export const Navigation = () => {
  const { isDesktop } = useSettings();

  return isDesktop ? <DesktopNavigation /> : <MobileNavigation />;
};
