import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

export interface userState {
  token: string;
  isLogin: boolean;
  loginTimestamp: number | null;
}

const initialState: userState = {
  token: '',
  isLogin: false,
  loginTimestamp: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state: userState, action: PayloadAction<any>) => {
      state.token = action.payload;
      state.isLogin = true;
      state.loginTimestamp = Math.floor(Date.now() / 1000);
      localStorage.removeItem('persist:root');
    },
    signOut: (state: userState) => {
      state.token = '';
      state.isLogin = false;
      state.loginTimestamp = null;
      localStorage.removeItem('jwt');
      localStorage.removeItem('curentGW');
      localStorage.removeItem('persist:root');
    },
  },
});

export const { signIn, signOut } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user;
