import { useDispatch } from 'react-redux';

import { navigation } from '@/modules/Navigation/helpers/navigation.mock';

import { signOut } from '@/store/slices/user.slice';

export const useAuth = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signOut());
    window.location.assign(navigation.login);
  };

  return { logout };
};
