const colors = {
  mainBlue: '#0A143C',
  primaryBlue: '#1A2345',
  secondaryBlue: '#273256',
  absoluteWhite: '#FFFFFF',
  primaryOrange: '#F06424',
  opacityWhite: 'rgba(255, 255, 255, 0.35)',

  ingrisBrown: 'rgba(245, 135, 32, 0.44)',

  primaryGrey: '#E8E8E8',
  imnerGrey: '#92A2B9',
  gemotsGrey: '#C5D0D4',
  bladeGrey: '#566789',
  dionisGrey: '#C8D0DC',
  broxGrey: '#EFF1F4',
  syrexGrey: '#C2D2D8',
  dulinGrey: '#CDD5DC',
  grahamGrey: '#BCBCBC',

  absoluteBlack: '#000000',

  cuksBlue: '#0A2540',
  talsBlue: '#0F172A',
  valdreBlue: '#091A34',
  oxfordBlue: '#001A3F',
  shinelBlue: '#0A3878',
  folmarBlue: '#0254D6',
  aolisBlue: '#003D66',
  siverilBlue: '#002B47',
  jhaerosBlue: '#0262F9',
  ilitranBlue: 'rgba(15, 23, 42, 0.2)',

  brekYellow: '#FDB61B',

  sontarGreen: '#68FF89',

  rydelRed: '#E5202F',
  vividRed: '#E44242',

  crinGradient:
    'radial-gradient(1678.7% 4104.44% at 11.84% -453.85%, #2677EE 0%, #00204C 100%)',

  surface02: '#130422',
  surface04: '#2A2A34',

  GradientTertiary: 'linear-gradient(90deg, #CF1856 0%, #A409A3 100%)',
  teamBg: '#3D3D4A',
  PrimaryPurple: '#9E07AE',
  primaryAccentGradient: 'linear-gradient(90deg, #CF1856, #A409A3)',
  TextSecondaryText: 'rgba(255, 255, 255, 0.50)',
  mainGradient: 'linear-gradient(90deg, #E5202F 0%, #F06424 100%)',

  navigationBg: '#051224',
  primaryYellow: '#FAA51A',
  primaryText: '#FFFFFF',
  incorrectRed: '#EA4451',
  correctGreen: '#68FF89',
  authBg: '#292934',
  borderImageGradient:
    'linear-gradient(to right, #9E07AE, #E5202F, #F06424, #FAA51A, #FAA51A)',
  imageBackground: 'rgba(255, 255, 255, 0.50)',
  navigationGradient: 'linear-gradient(180deg, #3E3E56 0%, #18181D 100%)',
  GradientBlue: 'linear-gradient(180deg, #3E3E56 0%, #18181D 100%)',
  Surface01: '#1D0A30',
  sidebarGradient: 'linear-gradient(180deg, #3E3E56 0%, #18181D 100%)',

  GradientPrimary:
    'linear-gradient(95deg, #9E07AE 0%, #E5202F 36.57%, #F06424 71.59%, #FAA51A 106.38%, #FAA51A 106.38%))',
};

export default colors;
