import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { navigation } from '@/modules/Navigation/helpers/navigation.mock';
import { selectUser } from '@/store';

import { useSidebarContext } from '@/contexts/sidebar.context';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGenerateJWTTokenMutation } from '@/services/api.service';

import { localStorageSet } from '@/utils/localStorage.util';

export const useNavigation = (setIsOpen?: (value: boolean) => void) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onSidebarClose } = useSidebarContext();

  const currentURL = window.location.href;

  const { isLogin } = useSelector(selectUser);
  const { token } = useSelector(selectUser);

  const { optaFour, optaStreak, myOpta } = navigation;

  const [generateToken] = useGenerateJWTTokenMutation();

  const handleNavigateOpta = useCallback(
    async (baseUrl: string, authPath: string, path: string) => {
      if (isLogin) {
        try {
          const response = await generateToken().unwrap();
          if (response?.jwt_redirect) {
            localStorage.removeItem('currentGW');
            const targetURL =
              currentURL && currentURL.includes('theanalyst')
                ? `${baseUrl}.theanalyst.com${authPath}?token=${response?.jwt_redirect}`
                : `${baseUrl}.soccerway.com${authPath}?token=${response?.jwt_redirect}`;
            window.location.replace(targetURL);
          }
        } catch (error) {
          console.error('error:', error);
        }
      } else {
        const targetURL =
          currentURL && currentURL.includes('theanalyst')
            ? `${baseUrl}.theanalyst.com${path}`
            : `${baseUrl}.soccerway.com${path}`;
        window.location.replace(targetURL);
      }
    },
    [isLogin, token, currentURL],
  );

  const handleNavigate = useCallback((path: string) => {
    // Navigate to external site
    if (!path.includes(currentURL)) {
      return window.location.assign(path);
    }

    if (path === navigation.superBracketPrizes) {
      // onPrizingOpen();
      onSidebarClose();
      return;
    }

    if (path !== optaFour && path !== optaStreak) {
      navigate(path);
      setIsOpen?.(false);
    }

    if (path === myOpta) {
      localStorageSet('prof', pathname);
      localStorageSet('profile', 'true');
      if (!isLogin) {
        navigate(RoutesEnum.login);
      } else if (isLogin) {
        navigate(path);
        localStorage.removeItem('prof');
        localStorage.removeItem('profile');
      }

      setIsOpen?.(false);
    }

    if (path === optaFour) {
      handleNavigateOpta('https://optaFour', '/epl/auth', '/epl/');
    }

    if (path === optaStreak) {
      handleNavigateOpta('https://optastreak', '/tabs/lobby', '/');
    }
  }, []);

  return { handleNavigate };
};
